<template>
    <ErrorRenderer v-if="capturedError" />

    <template v-if="isMobileOrTablet">
        <MobileLookup v-bind="{ ...props, ...$attrs }" :ref="cpm => mobileLookup = cpm">
            <template v-for="(_index, name) in $slots" v-slot:[name]="data">
                <slot :name="name" :id="props.id" v-bind="data ?? {}" />
            </template>
        </MobileLookup>
    </template>
    
    <template v-else>
        <DesktopLookup v-bind="$attrs, props" :ref="cmp => desktopLookup = cmp">
            <template v-for="(_index, name) in $slots" v-slot:[name]="data">
                <slot :name="name" :id="props.id" v-bind="data ?? {}" />
            </template>
        </DesktopLookup>
    </template>
</template>

<script>
import DesktopLookup from './desktop.DataLookup.vue';

export default {
    inheritAttrs: false
}
</script>

<script setup>
import MobileProps from './props.mobile.ts';
import DesktopProps from './props.desktop.ts';
import { useAsyncComponent, useErrorCapture } from 'o365-vue-utils';
import { useAttrs } from 'vue';
import { isMobileOrTablet } from "o365.GlobalState.ts";

let mobileLookup = null;
let desktopLookup = null;

const MobileLookup = useAsyncComponent('mobile.DataLookup.vue', { importFn: () => import('./mobile.DataLookup.vue') });

const attrs = useAttrs();
if (attrs) {
    if (attrs.onOnbeforeopen) {
        console.warn("@onbeforeopen is deprecated, use @beforeopen");
    }

    if (attrs.onOnbeforeclose) {
        console.warn("@onbeforeclose is deprecated, use @beforeclose");
    }

    if (attrs.onOnopen) {
        console.warn("@onopen is deprecated, use @open");
    }
}

function open() {
    if (desktopLookup?.activateEditor) {
        desktopLookup.activateEditor();
    } else if (mobileLookup?.open) {
        mobileLookup.open();
    }
}
function close() {
    if (desktopLookup?.closeEditor) {
        desktopLookup.closeEditor();
    } else if (mobileLookup?.close) {
        mobileLookup.close();
    }
}
const [capturedError, ErrorRenderer] = useErrorCapture({
    consoleMessagee: `Error encountered when trying to render DataLookup`,
    errorRenderFunctionOptions: {
        uiMessage: 'Lookup Render Error',
        uiTitleMessage: 'An error has occurred when rendering this lookup',
        type: 'span'
    }
});

const props = defineProps({ ...MobileProps, ...DesktopProps });

defineExpose({ open, close });
</script>
